<template>
  <b-card
    no-body style="min-width: 30%; max-width: 30%" class="my-1"
  >
    <b-card-header class="">
      <b-card-title>{{ release.name }}</b-card-title>
      <b-dropdown
        id="releases-dropdown-button"
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="createBuildConfigFromRelease(release.id)">
          <feather-icon icon="LinkIcon" class="cursor-pointer" />
          <span class="ml-50">Create Configuration</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateRelease(release.id)">
          <feather-icon icon="EditIcon" class="cursor-pointer" />
          <span class="ml-50">Update</span>
        </b-dropdown-item>
        <b-dropdown-item @click="deleteRelease(release.id)">
          <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" />
          <span class="text-danger ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="mb-2">
      <b-card-text>
        <div>{{ release.description }}</div>
        <b-row class="mt-50">
          <b-col>
            <b>Start Date</b>:
            <span v-if="release.start_date && release.start_date != ''">{{ release.start_date | moment }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b>Release Date</b>:
            <span v-if="release.end_date && release.end_date != ''">{{ release.end_date | moment }}</span>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-body>
    <b-card-footer>
      <div @click="updateRelease(release.id)">
        <b-button-group vertical size="lg" style="width:100%">
          <b-button variant="secondary" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ release.capabilities.length }}
            </b-badge> Capabilities Allocated
          </b-button>
          <b-button variant="secondary" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ release.functions.length }}
            </b-badge> Functions Allocated
          </b-button>
          <b-button variant="secondary" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ release.systems.length }}
            </b-badge> System Elements Allocated
          </b-button>
          <b-button variant="secondary" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ release.interfaces.length }}
            </b-badge> Interfaces Allocated
          </b-button>
          <b-button variant="secondary" style="text-align:left!important; padding-left:6px">
            <b-badge variant="primary">
              {{ release.requirements.length }}
            </b-badge> Requirements Allocated
          </b-button>
        </b-button-group>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import coreService from '@/libs/api-services/core-service'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ReleaseCard',
  directives: {
    Ripple,
  },
  filters: {
    moment(date) {
      return moment(date).format('LL')
    },
  },
  props: {
    releaseObject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    release() {
      return this.releaseObject
    },
  },
  methods: {
    updateRelease(releaseId) {
      console.log('Selecting release: ', releaseId)
      this.$store.dispatch('releases/selectRelease', releaseId)
      this.$store.dispatch('domainModel/getComponents')
      this.$store.dispatch('interfaces/fetchInterfaces')
      this.$bvModal.show('modal__ReleaseEdit')
    },
    deleteRelease(releaseId) {
      console.log('Selecting release: ', releaseId)
      this.$store.dispatch('releases/selectRelease', releaseId)
      this.$bvModal.show('delete-release-modal')
    },
    createBuildConfigFromRelease(releaseId) {
      const payload = {
        model: this.$store.state.model.id,
        release: releaseId,
      }
      coreService.post('/v1/legacy/domain_model/create_build_configuration_from_release', payload)
        .then(({ data }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Configuration Created',
              text: `Successfully created Configuration matching Release: ${data.id}`,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(r => {
          console.error(`[createBuildConfigFromRelease] failed - ${r}`)
          console.error(r)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create configuration',
              text: `An error occurred when attempting to create a Configuration for the Release.
              Server returned Status ${r}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>
