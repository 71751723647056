<template>
  <div>
    <div v-if="loading">
      <div class="w-100">
        <h1 class="mt-4 font-medium-3 text-center">
          Loading Release Information...
        </h1>
        <h5 class="text-center text-warning">
          <b-spinner />
        </h5>
      </div>
    </div>

    <b-container v-else>
      <b-row>
        <b-col>
          <b-button-group class="float-right mb-2">
            <b-button variant="outline-success" @click="$bvModal.show('add-release-modal')">
              Create Release Version
              <feather-icon icon="PlusIcon" />
            </b-button>
            <b-button
              v-if="!loading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="btn-icon"
              @click="fetchReleases"
            >
              <feather-icon icon="RefreshCcwIcon" />
              Refresh
            </b-button>
            <b-button
              v-if="sort === 'end'"
              variant="outline-info"
              @click="switchSort"
            >
              Order by Start Date
            </b-button>
            <b-button
              v-if="sort === 'start'"
              variant="outline-info"
              @click="switchSort"
            >
              Order by End Date
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-group deck>
            <div
              v-if="releases.length === 0"
              class="d-inline-flex justify-content-center w-100 mt-2"
            >
              <h4>There are currently no releases for this model.</h4>
            </div>
            <ReleaseCard
              :id="rel.id"
              :class="{ 'release-shadow': rel.id === $route.query.selected }"
              v-for="(rel, index) in releases"
              :key="`rel-${index}`"
              :release-object="rel"
              @click.native="selectRelease(rel.id)"
            />
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>

    <AddReleaseModal />
    <EditReleaseModal ref="edit_release_model" :interfaces="interfaces" :is-data-loaded="isDataLoaded" />
    <DeleteReleaseModal />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiStatus } from '@/enums'
import ReleaseCard from '@/components/Releases/Cards/ReleaseCard.vue'
import AddReleaseModal from '@/components/Releases/Modals/AddReleaseModal.vue'
import EditReleaseModal from '@/components/Releases/Modals/EditReleaseModal.vue'
import DeleteReleaseModal from '@/components/Releases/Modals/DeleteReleaseModal.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Releases',
  components: {
    DeleteReleaseModal,
    AddReleaseModal,
    EditReleaseModal,
    ReleaseCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected_release: {
        name: '',
        description: '',
      },
      sort: 'end',
      apiStatus,
      loading: true,
      requirements: [],
      interfaces: [],
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapState({
      releases: state => state.releases.releases,
    }),
  },
  mounted() {
    this.fetchReleases().then(() => {
      if (this.$route.query.selected) {
        const element = document.getElementById(this.$route.query.selected)
        element.scrollIntoView({ block: 'center' })
      }
    })
    this.getData()
  },
  methods: {
    async fetchReleases() {
      this.loading = true
      await this.$store.dispatch('releases/getReleases', this.sort)
      this.loading = false
    },
    switchSort() {
      if (this.sort === 'end') {
        this.sort = 'start'
      } else {
        this.sort = 'end'
      }
      this.fetchReleases()
    },
    getData() {
      this.isDataLoaded = false
      const params = { model: this.$store.state.model.id }
      this.$http.get('/v1/legacy/interfaces', { params })
        .then(({ data }) => {
          this.interfaces = data
        })
        .finally(() => {
          this.$refs.edit_release_model.removeDuplicatesForAssociators()
          this.isDataLoaded = true
        })
    },
    selectRelease(relId) {
      this.$router.push({
        name: 'model_releases',
        query: { selected: relId },
      })
    },
  },
}
</script>

<style scoped>
.release-shadow{
  box-shadow: 0 0 5px 5px rgba(115,103,240,0.5);
}
</style>
